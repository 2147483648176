import { React,useState} from "react";
import "./Contact.css";
import {FaHome} from "react-icons/fa"
import {BsFillTelephoneFill} from "react-icons/bs"
import {MdEmail} from "react-icons/md"
import { Row, Col, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const submitEmail = (e) => {
    e.preventDefault();
    console.log({ mailerState });
    
    emailjs.sendForm('service_jhl8pzf','template_d5q6pc9',e.target,'ZmYGGwsO3XtD3pXT4');
     
        setMailerState({
          email: "",
          name: "",
          subject: "",
          message: "",
        });
      
  };
  return (
    <section className="contact-section">
      <div className="bg-contact">
        <Col className="heroTextSection">
          <div className="main-text">
            <div className="main-div-contact">
              <h5 className="main-subtitle">Meta Brothers Ehf</h5>
            </div>
            <div>
              <h2 className="main-title">Build your dream</h2>
            </div>
          </div>
        </Col>
      </div>

      <Container id="hire-container">
        <Row id="hire-section">
          <Col lg={6}>
            <div className="contact-section">
              <h5 className="contact-us">Contact us</h5>
            </div>
            <div>
              <h2 className="keep-in-touch">Keep in touch</h2>
            </div>
            <div>
              <form className="form-wrapper" onSubmit={submitEmail}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name *"
                  onChange={handleStateChange} 
                  value={mailerState.name}
                ></input>
                <input
                  type="text"
                  name="email"
                  placeholder="Your Email *"
                  onChange={handleStateChange}
                  value={mailerState.email}
                ></input>
                  <input type="text" 
                  name="subject" 
                  placeholder='Write a subject *' 
                  onChange={handleStateChange} 
                  value={mailerState.subject}></input>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Write a message"
                  onChange={handleStateChange}
                  value={mailerState.message}
                ></textarea>
                <button className="hire-button" type="submit">
                  SEND
                </button>
              </form>
            </div>
          </Col>
          <Col lg={6} className="all-icons">
            <div className="div-icons">
              <FaHome className="contact-icons"/>
              <p className="p-icons">Funahöfði 17 ,110 reykjavik</p>
            </div>
            <div className="div-icons">
              <BsFillTelephoneFill className="contact-icons"/>
            <p className="p-icons">003547676696</p>
            </div>
            <div className="div-icons">
            <MdEmail className="contact-icons"/>
            <p className="p-icons">metabrothersehf@gmail.com</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Contact;
