import React from "react";
import "./Wallpaper.css";
import { HashLink as Link } from "react-router-hash-link";

const Wallpaper = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <section className="wallpaper-section">
      <div className="wallpaper-image">
        <h1 className="wallpaper-title">
          We bring over 13 years of market experience
        </h1>
        <h5 className="wallpaper-description">
          Discover our deep-rooted expertise and unrivaled understanding of the
          industry
        </h5>
        <div className="other-details">
          <span className="telephone">003547676696</span>
          <span className="or">or</span>
          <Link to="/contact" onClick={scrollToTop}>
            <button className="contact-button">Contact us</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Wallpaper;
