import React, { useState, useEffect } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/meta_brothers_logo-1-removebg-preview.png"
import "./Nav.css";


const Nav = () => {


  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  //Kalimi nga formati per pc ne tablet/telefona
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);





  return (
    <section id="navbar">
      <div className="logo">
       <Link
            className="navbarLinks"
            smooth
            to="#nav"
          >
            <img
              className="navbar-logo"
              src={logo}
              alt="logo"
            />
          </Link>
      </div>
      <div className="all-items">
        {(toggleMenu || screenWidth > 900) && (
        <ul className="list ms-auto">
          <li className="items">
            <Link
              className="navbarLinks"
              smooth
              to=""
            >
                Home
            </Link>
          </li>
          <li className="items">
            <Link
              className="navbarLinks"
              smooth
              to="#about-container"
            >
             About
            </Link>
          </li>
          <li className="items">
            <Link
              className="navbarLinks"
              smooth
              to="#service-section"
            >
             Services
            </Link>
          </li>
          <li className="items">
            <Link
              className="navbarLinks"
              smooth
              to="#portfolio-section"
            >
              Projects
            </Link>
          </li>
          <li className="items">
            <Link
              className="navbarLinks contact"
              smooth
              to="/contact"
            >
              Contact
            </Link>
          </li>
        </ul>
        )}
      </div>
      <button onClick={toggleNav} className="menu-but">
        <FontAwesomeIcon icon={faBars} />
      </button>

    </section>

    

    
      
    
  );
};
export default Nav;
