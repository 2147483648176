import { BrowserRouter, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Fragment } from "react";
import React from "react";
import "../src/App.css";
import Nav from "./components/Navbar/Nav";
import Main from "./components/Main/Main";
import Whyus from "./components/Whyus/Us";
import About from "./components/About/About";
import Services from "./components/Whatwedo/Services";
import Footer from "./components/Footer/Footer";
import Portfolio from "./components/Portfolio/Portfolio";
import Wallpaper from "./components/Wallpaper/Wallpaper";
import Contact from "./components/Contact/Contact";

function App() {
  AOS.init();
  return (
    <Fragment>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Nav />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Whyus />} />
          </Routes>
          <Routes>
            <Route path="/" element={<About />} />
          </Routes>
           <Routes>
            <Route path="/" element={<Services />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Wallpaper/>} />
          </Routes> 
          <Routes>
            <Route path="/" element={<Portfolio />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Footer />} />
          </Routes>
          <Routes>
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Fragment>
  );
}
export default App;
