import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { MdContactPhone } from "react-icons/md";
import { PiCaretRightBold } from "react-icons/pi";
const Footer = () => {
  return (
    <Container fluid id="footer">
      <Container className="footer-container">
        <Row>
          <Col md={4}>
            <h3 className="footer-title">Services</h3>

            <ul className="list-footer">
              <li>
                <PiCaretRightBold />
                <span >Parket</span>
              </li>
              <li>
                <PiCaretRightBold />
                <span >Flisarar</span>
              </li>
              <li>
                <PiCaretRightBold />
                <span >Múrarar & Málarar</span>
              </li>
              <li>
                <PiCaretRightBold />
                <span >Gifsvinna</span>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h3 className="footer-title">Working hours</h3>
            <div className="working-hours">
              <p className="working-hours-description">
                We work 6 days a week.
                Contact us if you have an emergency, with our phone number and
                Contact form.
              </p>
              <Row>
                <Col>
                  <p>Monday-Saturday</p>
                </Col>
                <Col>
                <p>07:00 - 18:00</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={4}>
            <h3 className="footer-title">About us</h3>
            <div className="footer-about">
                <p className="footer-about-description">
                We are an experienced team specializing in Flisarar, Málarar, Parket and Múrarar.
                </p>
                <p>
                    <MdContactPhone/><span>+003547676696</span>
                </p>
                <p>
                    <AiOutlineMail/><span> metabrothersehf@gmail.com</span>
                </p>
                <p className="facebook"><a href="https://www.facebook.com/metabrothers2023"><FaFacebookF className="facebook-icon"></FaFacebookF></a></p>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Footer;
