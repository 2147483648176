import React from "react";
import image from "../../assets/construction-worker.jpg";
import { Col, Container, Row } from "react-bootstrap";
import "./About.css";
const About = () => {
  return (
    <Container id="about-container">
      <Row id="about-section">
        <Col md={6}>
          <div className="about-first">
            <div>
              <h5 className="about-first-title">About us</h5>
            </div>
            <div>
              <h2 className="about-title">We build for your comfort</h2>
            </div>
            <div>
              <p className="about-description">Established with a vision to redefine the concept of interior aesthetics, our company stands as a trusted name in the industry, known for our unparalleled craftsmanship in parket, málarar, murárar, flisarar and gifsvinna. With a team of highly skilled artisans and a meticulous approach to every project, we strive to bring your vision to life, creating captivating and personalized spaces that reflect your unique style and elevate the ambiance of any environment. From concept to completion, we are dedicated to delivering exceptional quality, innovation, and customer satisfaction, making us the ideal choice for those seeking remarkable and artistically inspired transformations.</p>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="dots">
          <div style={{ textAlign: "center" }} className="about-img">
            <img src={image} className="my-image" alt="my"></img>
          </div>
          </div>
         
        </Col>
      </Row>
    </Container>
  );
};

export default About;
