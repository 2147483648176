import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Us.css";
import { MdOutlineDesignServices, MdArchitecture } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";

const Us = () => {
  return (
    <Container fluid id="services">
      <Container className="services-icon">
        <Row>
          <Col className="col-services" md={4}>
            <div className="mt-5 mb-4">
              <MdOutlineDesignServices className="design" />
              <p className="design-des">Who we are</p>
              <p className="services-description">
              We are an experienced company specializing in parket, málarar, murárar, flisarar, gifsvinna and decorative finishes.
              </p>
            </div>
          </Col>
          <Col className="col-services" md={4}>
            <div className="mt-5 mb-4">
              <RiComputerLine className="design" />
              <p className="design-des">What we do</p>
              <p className="services-description">
              We transform spaces with our expertise in beautifully laying parket, executing intricate plasterworks, installing tiles, and creating stunning decorative finishes.
                
              </p>
            </div>
          </Col>
          <Col className="col-services" md={4}>
            <div className="mt-5 mb-4">
              <MdArchitecture className="design" />
              <p className="design-des">Why choose us</p>
              <p className="services-description">
              Choose us for our mastery in the craft of parket, málarar, murárar, flisarar and gifsvinna, ensuring your space receives the utmost care, precision, and aesthetic excellence.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Us;
