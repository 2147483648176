import React from "react";
import "./Main.css";
import { Col } from "reactstrap";

const Main = () => {
  return (
    <section id="landing">
      <div className="bg">
        <Col className="heroTextSection">
          <div className="main-text">
            <div className="main-div">
              <h5 className="main-subtitle">Meta Brothers Ehf</h5>
            </div>
            <div>
              <h2 className="main-title">Build your dream</h2>
            </div>
            <div></div>
          </div>

          <div className="button-section">
            <a href="#about-section">
              <button className="read-button">
                <p>GET STARTED</p>
              </button>
            </a>
          </div>
        </Col>
      </div>
    </section>
  );
};
export default Main;
