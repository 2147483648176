import React from "react";
import "./Services.css";
import { Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { MdConstruction,MdEngineering,MdFormatPaint,MdGridOn } from "react-icons/md";
import {GiBrickWall} from "react-icons/gi"


const Services = () => {
  return (
    <section id="service-section">
      <div className="container">
        <Row>
          <div>
            <div className="mt-4">
              <h5 className="services-subtitle">What we do</h5>
            </div>
            <div>
              <h2 className="services-title">Our Exceptional Services</h2>
            </div>
            <div>
              <p className="service-description">
              Embark on a transformative journey where craftsmanship meets creativity, as our exceptional range of services unveils a world of artistic possibilities
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <Row>
            <Col md={6}>
              <Card className="service-card">
                <div className="icon">
                  <MdConstruction />
                </div>
                <h3 className="service-title">Parket</h3>
                <p className="each-service-description">We are a specialized team in the art of parket, creating mesmerizing spaces that blend elegance, durability, and our craftsmanship. With meticulous attention to detail and the utilization of cutting-edge techniques, we ensure that every inch of parket is flawlessly installed. </p>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="service-card"> <div className="icon">
                  <MdFormatPaint />
                </div>
                <h3 className="service-title">Múrarar & Málarar</h3>
                <p className="each-service-description">Our Múrarar and Málarar services are a testament to our commitment to transforming surfaces into works of art. We take pride in delivering impeccable solutions that breathe new life into any space. From flawlessly smooth finishes to intricate textures, our attention to detail ensures a flawless result every time</p></Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card className="service-card"> <div className="icon">
                  <MdGridOn />
                </div>
                <h3 className="service-title">Flisarar</h3>
                <p className="each-service-description">We are dedicated to the art of Flisarar, turning ordinary spaces into extraordinary . With our skilled craftsmen and a keen eye for design, we bring to life stunning tile installations that enhance the beauty and functionality of any environment.  We offer a wide range of tile options to suit your style and vision.</p></Card>
            </Col>
            <Col md={6}>
              <Card className="service-card"> <div className="icon">
                  <GiBrickWall />
                </div>
                <h3 className="service-title">Gifsvinna</h3>
                <p className="each-service-description">Our skilled craftsmen are specialized in the intricate art of Gifsvinna, transforming ordinary walls into stunning pieces. With a meticulous approach and a keen eye for detail, we create textured finishes, decorative moldings, and unique designs that add elegance to any space.</p></Card>
            </Col>
          </Row>
        </Row>
      </div>
    </section>
  );
};

export default Services;
